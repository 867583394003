// src/services/strapi.service.js
// This file uses ES6 modules (ESM)

// Purpose: Provides RTK Query endpoints for interacting with the Strapi API

import { createApi } from "@reduxjs/toolkit/query/react";
import qs from "qs";
import { fetchStrapiService } from "@/services/apiService/fetchStrapiService";

const articlesPopulate = [
  "categories",
  "author",
  "author.image",
  "seo.metaImage",
];

export const strapiApi = createApi({
  reducerPath: "strapi",
  baseQuery: async ({ url, method = "GET", params = {}, body }) => {
    try {
      const queryString = qs.stringify(params, { encodeValuesOnly: true });
      const fullUrl = queryString ? `${url}?${queryString}` : url;

      const options = { method };
      if (body) {
        options.body = JSON.stringify(body);
      }

      const data = await fetchStrapiService.request(fullUrl, options);
      return { data };
    } catch (error) {
      console.error("Error in baseQuery:", error);
      return { error: error.toString() };
    }
  },
  endpoints: (builder) => ({
    getPrices: builder.query({
      query: () => ({
        url: "/price",
        params: {
          populate: ["priceData.advantages"],
        },
      }),
    }),
    getMembers: builder.query({
      query: () => ({
        url: "/member",
        params: {
          populate: ["images"],
        },
      }),
    }),
    getArticles: builder.query({
      query: ({ id, filters, pagination }) => {
        const params = {
          populate: articlesPopulate,
          filters,
          pagination,
          sort: ["createdDate:desc"],
        };
        const url = id ? `/articles/${id}` : "/articles";
        return { url, params };
      },
    }),
    getOneArticle: builder.query({
      query: (slug) => ({
        url: `/slugify/slugs/article/${slug}`,
        params: {
          populate: articlesPopulate,
        },
      }),
    }),
    getUsers: builder.query({
      query: () => ({
        url: "/authors",
        params: {
          populate: "*",
        },
      }),
    }),
    getAuthor: builder.query({
      query: (slug) => ({
        url: `/slugify/slugs/author/${slug}`,
        params: {
          populate: [
            "*",
            "image",
            "articles.categories",
            "articles.author",
            "articles.author.image",
            "articles.seo.metaImage",
          ],
        },
      }),
    }),
    getCategories: builder.query({
      query: () => ({
        url: "/categories",
        params: {
          populate: "*",
        },
      }),
    }),
    getCategory: builder.query({
      query: (slug) => ({
        url: `/slugify/slugs/category/${slug}`,
        params: {
          populate: [
            "*",
            "articles.categories",
            "articles.author",
            "articles.author.image",
            "articles.seo.metaImage",
          ],
        },
      }),
    }),
    getStaticPage: builder.query({
      query: (slug) => ({
        url: `/slugify/slugs/static-page/${slug}`,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetArticlesQuery,
  useGetArticlesQuery,
  useGetPricesQuery,
  useGetMembersQuery,
  useGetOneArticleQuery,
  useGetUsersQuery,
  useGetAuthorQuery,
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useGetStaticPageQuery,
} = strapiApi;
