// src/store/index.js
// This file uses ES6 modules (ESM)
// Redux store configuration and setup

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createWebStorage from "redux-persist/lib/storage/createWebStorage"; // Imported for potential future (or restoring past) use with Redux Persist
import { aventureApi } from "@/services/apiService/aventureApi";
import { strapiApi } from "@/services/strapi.service";
// Importing slices
import authSlice from "./auth/auth.slice";
import profileSlice from "./profile/profile.slice";
import companiesSlice from "./companies/companies.slice";
import companiesListSlice from "./companiesList/companiesList.slice";
import filtersSlice from "./filters/filters.slice";
import fundsSlice from "./funds/funds.slice";
import contentSlice from "./content/content.slice";
import fundraisingSlice from "./fundraising/fundraising.slice";
import searchSlice from "./search/search.slice";
import peopleSlice from "./people/people.slice";
import tagsSlice from "./tags/tags.slice";
import acctFundsSlice from "./acctFunds/acctFunds.slice";
import notificationsSlice from "./notifications/notifications.slice";
import errorSlice from "./error/error.slice";
import viewsSlice from "./views/views.slice";
import newCompaniesListSlice from "./newCompaniesList/newCompaniesList.slice";
import newViewsSlice from "./newView/newView.slice";
import newListsSlice from "./newLists/newLists.slice";

/**
 * Root reducer combining all individual slices.
 *
 * Each slice represents a part of the state and handles its own actions and reducers.
 */
const rootReducer = combineReducers({
  auth: authSlice.reducer,
  profile: profileSlice.reducer,
  companies: companiesSlice.reducer,
  companiesList: companiesListSlice.reducer,
  filters: filtersSlice.reducer,
  funds: fundsSlice.reducer,
  content: contentSlice.reducer,
  fundraising: fundraisingSlice.reducer,
  search: searchSlice.reducer,
  people: peopleSlice.reducer,
  tags: tagsSlice.reducer,
  acctFunds: acctFundsSlice.reducer,
  notifications: notificationsSlice.reducer,
  error: errorSlice.reducer,
  views: viewsSlice.reducer,
  newCompaniesList: newCompaniesListSlice?.reducer,
  newViews: newViewsSlice.reducer,
  newLists: newListsSlice.reducer,
  // API slices for data fetching and caching
  [strapiApi.reducerPath]: strapiApi.reducer,
  [aventureApi.reducerPath]: aventureApi.reducer,
});

/**
 * Configures and returns the Redux store.
 *
 * The store holds the global state of the application.
 *
 * @returns {Store} The configured Redux store.
 */
export const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    /**
     * Configures middleware for the Redux store.
     *
     * - Disables serializable checks to allow non-serializable data in actions and state (use with caution).
     * - Adds middleware for RTK Query APIs (aventureApi and strapiApi).
     */
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // Disable if you have non-serializable data
      }).concat(
        aventureApi.middleware,
        strapiApi.middleware
      ),
    /**
     * Enables Redux DevTools extension in non-production environments.
     */
    devTools: process.env.NODE_ENV !== "production",
  });
};

/**
 * Note:
 * - `createWebStorage` is imported for potential use with redux-persist in case you plan to persist the Redux store.
 * - Currently, it's not used in this file.
 */
