// src/services/apiService/fetchStrapiService.js
// This file uses ES6 modules (ESM)
//
// This service provides a wrapper around the Fetch API for interacting with the Strapi backend.
// It replaces axiosServiceStrapi.js and should be used in all code that interacts with Strapi.
//
// How to use:
// - Import `fetchStrapiService` and use its methods (`get`, `post`, `put`, `patch`, `delete`) to make API calls.
// - Supports additional options and headers.
// - Automatically includes the Authorization header if required environment variables are set.
// - Throws errors if required environment variables are missing.
// - Handles errors and logs relevant information.

export const fetchStrapiService = {
  async request(endpoint, options = {}) {
    const baseURL = process.env.NEXT_PUBLIC_STRAPI_API_URL;

    if (!baseURL) {
      throw new Error('Error: NEXT_PUBLIC_STRAPI_API_URL environment variable is missing.');
    }

    const url = `${baseURL}${endpoint}`;

    const token = process.env.STRAPI_API_TOKEN;
    if (!token) {
      throw new Error('Error: STRAPI_API_TOKEN environment variable is missing.');
    }

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'User-Agent':
        'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:106.0) Gecko/20100101 Firefox/106.0',
      'Authorization': `Bearer ${token}`,
      ...options.headers,
    };

    const config = {
      ...options,
      headers,
    };

    try {
      console.debug('Sending request to Strapi:', {
        url,
        method: config.method || 'GET',
      });
      const response = await fetch(url, config);

      if (!response.ok) {
        const errorInfo = {
          url: response.url,
          status: response.status,
          statusText: response.statusText,
          data: await response.text(),
        };
        console.error('Strapi Request Failed:', errorInfo);
        const error = new Error(`Strapi request failed: ${response.statusText}`);
        error.info = errorInfo;
        throw error;
      }

      console.debug('Successful Strapi response:', { url: response.url });
      return await response.json();
    } catch (error) {
      console.error('Error during Strapi fetch:', error);
      throw error;
    }
  },

  get(endpoint, options = {}) {
    return this.request(endpoint, { ...options, method: 'GET' });
  },

  post(endpoint, data, options = {}) {
    return this.request(endpoint, {
      ...options,
      method: 'POST',
      body: JSON.stringify(data),
    });
  },

  put(endpoint, data, options = {}) {
    return this.request(endpoint, {
      ...options,
      method: 'PUT',
      body: JSON.stringify(data),
    });
  },

  patch(endpoint, data, options = {}) {
    return this.request(endpoint, {
      ...options,
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  },

  delete(endpoint, options = {}) {
    return this.request(endpoint, { ...options, method: 'DELETE' });
  },
};
