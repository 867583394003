// src/providers/DefaultProvider.js
// This file uses ES6 modules (ESM)
"use client";

import ErrorProvider from "@/providers/ErrorProvider";
import { makeStore } from "@/store/index";
import "@/styles/globals.css";
import theme from "@/styles/theme";
import { sentryFeedbackHandler } from "@/utils/sentryFeedback";
import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SessionProvider } from "next-auth/react";
import localFont from "next/font/local";
import { usePathname } from "next/navigation";
import Script from "next/script";
import { useEffect, useRef } from "react";
import { Provider } from "react-redux";

const helvetica = localFont({
  src: [
    {
      path: "../styles/fonts/HelveticaLTPro-Roman.otf",
      weight: "400",
    },
    {
      path: "../styles/fonts/HelveticaLTPro-Bold.otf",
      weight: "700",
    },
  ],
});

export function DefaultProvider({ children, session }) {
  const pathname = usePathname();
  const storeRef = useRef();

  if (!storeRef?.current) {
    storeRef.current = makeStore();
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      (() => {
        "use strict";
        var t,
          e = {
            appId: process.env.NEXT_PUBLIC_ATLAS_APP_ID,
            v: 2,
            q: [],
            call: function () {
              this.q.push(arguments);
            },
          };
        window.Atlas = e;
        var n = document.createElement("script");
        (n.async = !0),
          (n.src = "https://app.atlas.so/client-js/atlas.bundle.js");
        var s = document.getElementsByTagName("script")[0];
        null === (t = s.parentNode) || void 0 === t || t.insertBefore(n, s);
      })();

      window.Atlas.call("start", {
        callbacks: {
          sentry: () => sentryFeedbackHandler(),
        },
      });
    }, 10);

    return () => clearTimeout(timer);
  }, []);

  return (
    <body
      className={helvetica.className}
      style={{
        background: pathname.startsWith("/companies") ? "white" : "#F0F2F5",
      }}
    >
      <Provider store={storeRef.current}>
        <ChakraProvider theme={theme}>
          <GoogleOAuthProvider
            clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}
          >
            <SessionProvider {...{ session }}>
              <ErrorProvider>{children}</ErrorProvider>
            </SessionProvider>
          </GoogleOAuthProvider>
        </ChakraProvider>
      </Provider>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}" height="0" width="0" style="display: none; visibility: hidden;"></iframe>`,
        }}
      />
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
      `}
      </Script>
    </body>
  );
}
